import GPX from 'ol/format/GPX.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import {IGC, KML, TopoJSON} from 'ol/format.js';
import ImageTile from 'ol/source/ImageTile.js';
import Map from 'ol/Map.js';
import VectorSource from 'ol/source/Vector.js';
import View from 'ol/View.js';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style.js';

//import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {Vector as VectorLayer} from 'ol/layer.js';
import TileLayer from "ol/layer/WebGLTile";

import {fromLonLat,toLonLat} from 'ol/proj';
import {transform} from 'ol/proj';
import {FullScreen, defaults as defaultControls} from 'ol/control.js';
import Control from 'ol/control/Control';
import Point from 'ol/geom/Point';
import {circular} from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import {getVectorContext} from 'ol/render.js';
import {LineString} from 'ol/geom.js';
import MousePosition from 'ol/control/MousePosition.js';
import OverviewMap from 'ol/control/OverviewMap.js';
import ScaleLine from 'ol/control/ScaleLine.js';
import ZoomSlider from 'ol/control/ZoomSlider.js';
import Attribution from 'ol/control/Attribution.js';
import * as olSphere from 'ol/sphere';
import MultiLineString from 'ol/geom/MultiLineString.js';
import Icon from 'ol/style/Icon.js';
import {Circle, Text} from 'ol/style.js';
import Overlay from 'ol/Overlay.js';
import {
	Modify,
  DragAndDrop,
  defaults as defaultInteractions,
} from 'ol/interaction.js';


//import ZoomToExtent from 'ol/control/ZoomToExtent.js';


const attributions =
  '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
  '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'+
  'Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)';


const varlayer = {saturation:0};


const raster = new TileLayer({
  source: new ImageTile({
    attributions: attributions,
	//  url:  'https://tile-c.opentopomap.cz/{z}/{x}/{y}.png',
		url: 'https://a.tile.opentopomap.org/{z}/{x}/{y}.png',
//      url: 'http://endurotrack.ru/cgi-bin/gettile.pl?z={z}&x={x}&y={y}',
    tileSize: 256,//512, //256
    maxZoom: 20,
  }),
});

  

const rastersat = new TileLayer({
  source:
  new ImageTile({
 //   attributions: attributions,
    url: 'http://endurotrack.ru/cgi-bin/gettile.pl?sat=1&z={z}&x={x}&y={y}',
    tileSize: 512,
    maxZoom: 20,
  }),
});


const style = {
  'gpxview':new Style({
  image: new Icon({
    anchor: [0.4, 0.95],
    src: '/gps.png',
	scale:0.09,
  }),
  text: new Text({
   // text: 'Start',
    font: 'bold 22px Calibri,sans-serif',
	offsetY:40,
    fill: new Fill({
      color: 'black',
    }),
    stroke: new Stroke({
      color: 'white',
      width: 2,
    }),
  }),
}),
  'enduroAngle':new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    src: '/mot.png',
	scale:0.07,
  }),
  text: new Text({
    text: 'Start',
    font: 'bold 22px Calibri,sans-serif',
	offsetY:40,
    fill: new Fill({
      color: 'black',
    }),
    stroke: new Stroke({
      color: 'white',
      width: 2,
    }),
  }),
}),
  'enduroAngle_mirror':new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    src: '/mot_mirror.png',
	scale:0.07,
  }),
  text: new Text({
    text: 'World\nText',
    font: 'bold 22px Calibri,sans-serif',
	offsetY:40,
    fill: new Fill({
      color: 'black',
    }),
    stroke: new Stroke({
      color: 'white',
      width: 2,
    }),
  }),
}),
  'StartPoint': new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: 'rgba(0,255,0,0.8)',
      }),
      radius: 6,
      stroke: new Stroke({
        color:'rgba(0,0,0,1)',
        width: 2,
      }),
    }),
  }),
  'EndPoint': new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: 'rgba(255,0,0,0.8)',
      }),
      radius: 6,
      stroke: new Stroke({
        color:'rgba(0,0,0,1)',
        width: 2,
      }),
    }),
  }),
  
  'Point': new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: 'rgba(255,0,0,0.4)',
      }),
      radius: 10,
      stroke: new Stroke({
        color: '#ff0',
        width: 3,
      }),
    }),
  }),
  'LineString': new Style({
    stroke: new Stroke({
      color: '#f00',
      width: 3,
    }),
  }),
  'MultiLineString': new Style({
    stroke: new Stroke({
      color: '#000',
      width: 5,
    }),
  }),
};





let colors = {
  'Clement Latour': 'rgba(0, 0, 255, 0.7)',
  '1': [0, 215, 255, 0.9],
  'Sylvain Dhonneur': 'rgba(0, 165, 255, 0.7)',
  'Tom Payne': 'rgba(0, 255, 255, 0.7)',
  'Ulrich Prinz': 'rgba(0, 215, 255, 0.7)',
};


let map;


document
  .querySelectorAll('.ol-zoom-in, .ol-zoom-out, .ol-rotate-reset')
  .forEach(function (el) {
    new bootstrap.Tooltip(el, {
      container: '#map',
    });
  });

const dragAndDropInteraction = new DragAndDrop({
  formatConstructors: [GPX, GeoJSON, IGC, KML, TopoJSON],
});

const vectorSource = new VectorSource();


const styleCache = {};
const styleFunction = function (feature) {
 
  let color;
  if(feature){
	color=colors[feature.ol_uid];  
  }else{
	color==colors[1]; //colors[feature.get('PLT')];
  }

  let style = styleCache[color];
  if (!style) {
    style = new Style({
      stroke: new Stroke({
        color: color,
        width: 3,
      }),
	  image: new CircleStyle({
      fill: new Fill({
        color: 'rgba(255,0,0,0.4)',
      }),
      radius: 10,
      stroke: new Stroke({
        color: '#ff0',
        width: 3,
      }),
    }),
	
    });
    styleCache[color] = style;
  }
  
  let styles=[];
  styles.push(style);
  
  if(feature){
  let geometry=feature.getGeometry();
                  // Arrow style
  var lineStringsArrays = geometry.getCoordinates();
	for (var i = 0; i < lineStringsArrays.length; i++) {
		var ar=lineStringsArrays[i];
		var step=0;
		var std=ar[0];
		for (var j = 0; j < ar.length-1; j++) {
			var start=ar[j];
			var end=ar[j+1];
			var arrowLonLat = [(end[0] + start[0]) / 2, (end[1] + start[1]) / 2];
			var dx = end[0] - start[0];
			var dy = end[1] - start[1];
			//step=step+Math.abs(dx);
			step++;
			var rotation = Math.atan2(dy, dx);
			var dis=olSphere.getDistance(toLonLat(std),toLonLat(end));
  //                           // Arrow picture style
			let z=map.getView().getZoom();
			if((dis>20/z*300)&&( z>13)){
				std=end;
				step=0;
			styles.push(new Style({
				zIndex:2,
				geometry: new Point(arrowLonLat),
				image: new Icon({
					src: '/st.png',
					anchor: [0.75, 0.5],
					rotateWithView: true,
				   rotation: -rotation,
				   scale:0.6,
				   opacity:0.5
				})
			 }));
			}
		}
	}
  }
	
  return styles;
 // return style;
};


// стиль для поверхностного трека
var styleFunctionforMytrack = function (feature) {
	if(!feature){
		var st=new Style({
                stroke: new Stroke({
					// цвет делаю темнее
                   // color: color,
                    width: 4,
					opacity:0.5,
					
                }),
					image: new CircleStyle({
					radius: 5,
					fill: new Fill({
									color: 'rgba(255,0,0,0.9)',
									}),
					stroke: new Stroke({
					// цвет делаю темнее
                    color:  'rgba(255,0,0,0.9)',
                    width: 4,
					
                }),
					
			  }),
            });
			return [st];
	}
	  
	  

       var geometry = feature.getGeometry();
                 // line segment style
		//let ovstyle=vectorLayer.getStyle();		 
		//let color=colors[feature.ol_uid].map(function (item, index) { 
		//								if(index<3){	return Math.round(item/2);}else{return 1;}// [Math.round(item[0]/2),Math.round(item[1]/2),Math.round(item[2]/2),item[3]]; 
		//							});
									
		let color=[0,0,0,0.7];							
        var styles = [
            new Style({
                stroke: new Stroke({
					// цвет делаю темнее
                    color: color,
                    width: 4,
									
                }),
					image: new CircleStyle({
					radius: 5,
					fill: new Fill({
									color: 'rgba(255,0,0,0.9)',
									}),
					stroke: stroke,
					opacity:0.5,
					
			  }),
            })
        ];
 

        return styles;
};


const gpxUrls = [
  //'https://energy1.ru/file2.gpx',
//  'file.gpx',
//  'file4.gpx',
//  '1.gpx',
//  '2.gpx',
];

//const time = {
//  this.start=Infinity;
//  this.stop= -Infinity;
//  this.duration=0;
//};

function Time(name) {
  this.start= Infinity;
  this.stop= -Infinity;
  this.duration= 0;
}

function OverTrackData() {
  this.name='';
  this.data= -Infinity;
}

function emptyObject(obj) {
  Object.keys(obj).forEach(k => delete obj[k])
}

const ObjOverTrackData={};
const TrackName={};
  
 
const meta={};
let CurrentTrack=null;
let CurrentFeature=null;


const urlParams = new URLSearchParams(window.location.search);
const idtrack = urlParams.get('id');
const idcompare = urlParams.get('compare');

if(idtrack!=null) {
	gpxUrls.push('https://endurotrack.ru/cgi-bin/getgpx.pl?track='+idtrack.toString());
	downloadgpx();
}
else if(idcompare!=null)
{
  get('https://endurotrack.ru/endurotrackbot/json/'+idcompare+'.json', function (data) {
	 // if(JSON.isRawJSON(data)){
		let myid;
	   const obj = JSON.parse(data,(key, value) => {
		   
  		   if(key==="id"){
			myid='https://endurotrack.ru/cgi-bin/getgpx.pl?track='+value.toString();
			gpxUrls.push(myid);
			
		   }
  		   if(key==="name"){
			TrackName[myid]=value.toString();
		   }
		   

		});
		
	 downloadgpx(); 
	 // }
  });	
	
	 console.log(gpxUrls);
}else{
	
	const infoonmap = document.getElementById('infoonmap');
	infoonmap.innerHTML ="<strong>Сайт работает в режиме расширения функциональности телеграмм бота <a href='https://t.me/EnduroTrackBot'>@endurotrackbot</a></strong>";
	infoonmap.style.opacity = 1;	
	
}


class ViewAllControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = 'All';

    const element = document.createElement('div');
    element.className = 'ViewAll ol-unselectable ol-control';
  
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.ViewAll.bind(this), false);
  }

  ViewAll() {
	  this.getMap().getView().fit(vectorSource.getExtent());
  }
}

class RotateNorthControl extends Control {
  /**
   * @param {Object} [opt_options] Control options.
   */
  constructor(opt_options) {
    const options = opt_options || {};

    const button = document.createElement('button');
    button.innerHTML = 'N';

    const element = document.createElement('div');
    element.className = 'rotate-north ol-unselectable ol-control';
  
    element.appendChild(button);

    super({
      element: element,
      target: options.target,
    });

    button.addEventListener('click', this.handleRotateNorth.bind(this), false);
  }

  handleRotateNorth() {
    this.getMap().getView().setRotation(0);
  }
}

// значек загрузка
//document.getElementById('map').classList.add('spinner');



 
function loadGPXFeatures(features) { 
 
	let myfeature;
	for (let j = 0; j < features.length;j++) {
			if(features[j].getGeometry().getType()==='MultiLineString'){
				myfeature=features[j];
			}
	} 

	if(myfeature===undefined){return;}

	let myData=myfeature.getGeometry().getCoordinates();
	let newData=[];
	let len=0;
	let dis=0;
	
	//добавляем индекс и дистанцию к каждой координате
	if(myfeature.getGeometry().getType()==='MultiLineString'){
		let lasttoint=myData[0][0];
		for (let j = 0; j < myData.length;j++) {
			let seg=myData[j];
			var positions = seg.map(function (item, index) { 
										dis=dis+olSphere.getDistance(toLonLat(item),toLonLat(lasttoint));
										lasttoint=item;
										return [item[0],item[1],item[2],item[3], index+len,dis]; 
									});
			newData.push(positions);
			len=len+seg.length;
		}
	}else if(myfeature.getGeometry().getType()==='LineString')
	{
		let lasttoint=myData[0];
			var positions = myData.map(function (item, index) { 
										dis=dis+olSphere.getDistance(toLonLat(item),toLonLat(lasttoint));
										lasttoint=item;
										return [item[0],item[1],0,0, index,dis]; 
									});
			newData.push(positions);
	}

	 // заносим обновленные координаты
	  myfeature.getGeometry().setCoordinates(newData);
	 
	 // запоминаем время старта и финиша, пока пусто
	 const mytime= new Time();
     myfeature.set('time',mytime);
	 
	 //точка старта
	const startpoint = new Feature({
		geometry: new Point(myfeature.getGeometry().getFirstCoordinate()),
	});
	startpoint.setStyle(style.StartPoint);
	featureOverlay.getSource().addFeature(startpoint); 
	 //точка финиша
	const endpoint = new Feature({
		geometry: new Point(myfeature.getGeometry().getLastCoordinate()),
	});
	endpoint.setStyle(style.EndPoint);
	featureOverlay.getSource().addFeature(endpoint); 
	
	// задаем тип данных 
	myfeature.getGeometry().layout='XYZM';
	
	myfeature.setStyle(styleFunction);
  //добавляем на векторный слой карты
    vectorSource.addFeature(myfeature);
	
	
    
	//TrackOverlay.getSource().addFeature(myfeature); 

return myfeature;

}

function get(url, callback) {
  const client = new XMLHttpRequest();
  client.open('GET', url);
  console.log(url);
  client.onload = function () {  callback(client.responseText);  };
  client.send();
}

let countload=0;



function downloadgpx(){
// загружаем треки
const GpsFormat = new GPX();
if (map){
	map.getTargetElement().classList.add('spinner');
}
 
let html=[];
for (let i = 0; i < gpxUrls.length; ++i) {
	
// значек загрузка
//document.getElementById('map').classList.add('spinner');
	
  get(gpxUrls[i], function (data) {
    const features = GpsFormat.readFeatures(data, {
      featureProjection: 'EPSG:3857',
    });

	meta[gpxUrls[i]] = GpsFormat.readMetadata(data);
	
	let myfeature=loadGPXFeatures(features);
	
	let name=gpxUrls[i];

	 if(TrackName[gpxUrls[i]]!=null){
       name=TrackName[gpxUrls[i]];
	 }else
	 if(meta[gpxUrls[i]]!=null){
       name=meta[gpxUrls[i]].name;
	 }

   html.push('<li ><a class="dropdown-item" href="#">');
   html.push('<input name="CheckGPX" style="accent-color:'+colors[myfeature.ol_uid]+';" type="checkbox" value='+myfeature.ol_uid+' id="CheckGPX'+i.toString()+'" checked>');
   html.push('<label class="form-check-label" for="CheckGPX'+i.toString()+'">'+name+'</label>');
   html.push('</a></li>');

	 myfeature.setProperties({
    //            id: '1',
                'name': name,
    //            desc: 'test desc',
    //            type: "track",
            });
	countload++;

	if(countload>=gpxUrls.length)
	{
		map.getTargetElement().classList.remove('spinner');
		map.getView().fit(vectorSource.getExtent());
    const gpxlist_ul = document.getElementById('gpxlist_ul');
    gpxlist_ul.innerHTML=html.join('');
    let selected = [];
    gpxlist_ul.addEventListener('change', event => {
      if (event.target.type === 'checkbox') {
        const checked = document.querySelectorAll('input[name="CheckGPX"]:checked')
        selected = Array.from(checked).map(x => x.value)
      }

      vectorSource.forEachFeature(function (feature) {
        if(!selected.includes(feature.ol_uid)){
          feature.setStyle(new Style({}));   
          feature.set('hidden',true);
        }else{
          feature.setStyle(styleFunction); 
          feature.unset('hidden');
        }
      });

    })
  
		
	}
	
  });
}

 
}

function makeColor(colorNum, lightness){
    let hues = [0, 20, 30, 40, 50, 60, 80, 120, 160, 190, 210, 230, 260, 290, 330, 360];
    //hues = [...Array(37).keys()].map(x=>x*10);
    let lights = [60, 54, 50, 48, 47, 44, 47, 50, 50, 55, 67, 77, 73, 67, 60, 60];
    let goldenFrac = 0.5 * (3 - Math.sqrt(5));
    let x = (colorNum * goldenFrac % 1.0) * (hues.length - 1);
    //x=colorNum%(hues.length-1); // for point visualisation
    let i = Math.floor(x);
    let f = x % 1.0;
    let hue = (1.0 - f) * hues[i] + f * hues[i + 1];
    let light = (1.0 - f) * lights[i] + f * lights[i + 1];
    return "hsl( " + Math.round(hue * 100) / 100 + ", 100%, "+Math.round(light * 100) / 100+"% )";
}

vectorSource.on('addfeature', function (event) {
  const geometry = event.feature.getGeometry();
  const mytime= new Time();
  mytime.start = Math.min(mytime.start, geometry.getFirstCoordinate()[3]);
  mytime.stop = Math.max(mytime.stop, geometry.getLastCoordinate()[3]);
  mytime.duration = mytime.stop - mytime.start;
  
  const min = 50;
  const max = 150;
  const color=Math.floor(Math.random() * (max - min + 1)) + min;
 
  colors[event.feature.ol_uid]=makeColor(event.feature.ol_uid);//[Math.floor(Math.random() * (max - min + 1)) + min, 0, Math.floor(Math.random() * (max - min + 1)) + min,1];

 //'rgba('+color.toString()+', 0, 255, 0.7)';
 
 // console.log(event.feature.getProperties());
  if(event.feature.getGeometry().getType()=='MultiLineString'){
	  
  	if (!vectorSource.isEmpty()) {
		map.getView().fit(geometry, {
		maxZoom: 18,
		duration: 500,
    });
	}  
    
	event.feature.set('time',mytime);
    event.feature.setProperties({
    //            id: '1',
    //            desc: 'test desc',
    //            type: "track",
           });
  
		   
  }else{
   map.getView().setCenter(event.feature.getGeometry().getFirstCoordinate()[0]);
	  
  }
 
});


const vectorLayer = new VectorLayer({
  source: vectorSource,
//  style: styleFunction,
});



// угломер
const enduroAngle = new Feature({
  geometry: new Point(fromLonLat([38.990490,45.209960])),
});
enduroAngle.setStyle(style.enduroAngle);

const enduroAnglevectorSource = new VectorSource({
  features: [enduroAngle],
});

// GPX иконка
const GPXCheckfeature = new Feature({
  geometry: new Point(fromLonLat([38.990490,45.209960])),
});
GPXCheckfeature.setStyle(style.gpxview);

const GGPXCheckSource = new VectorSource({
  features: [GPXCheckfeature],
});



map = new Map({
  interactions: defaultInteractions().extend([dragAndDropInteraction]),
  controls: defaultControls().extend([
  //  new FullScreen({
  //    source: 'fullscreen',
  //  }),
//new MousePosition(),
	//new OverviewMap(),
	new ScaleLine(),
//	new ZoomSlider(),
	new Attribution(),
  ]),
  layers: [raster, vectorLayer],
//  layers: [enduroAngleLayer],
//    layers: [enduroAngleLayer,vectorLayer],
target: document.getElementById('map'),
  view: new View({
    center:  fromLonLat([38.990490,45.209960]),
    zoom: 12,
  }),
  moveTolerance:5,
});

//map.addControl(new RotateNorthControl());
map.addControl(new ViewAllControl());

//change map color
map.on('postcompose',function(e){
 //   document.querySelector('canvas').style.filter="grayscale(90%)";
  });  
  
//const enduroAngleLayer = new VectorLayer({
//  map: map,
//  source: enduroAnglevectorSource,
//  style:iconStyle,
//});

const GGPXCheckLayer = new VectorLayer({
  source: GGPXCheckSource,// new VectorSource(),
  map: map,
  style:style.gpxview,
  });
//GGPXCheckLayer.getSource().addFeature(GPXCheckfeature); 
GGPXCheckLayer.setVisible(false);

const modify = new Modify({
  hitDetection: GGPXCheckLayer,
  source: GGPXCheckSource,
});

modify.on(['modifystart', 'modifyend'], function (evt) {
  document.getElementById('map').style.cursor = evt.type === 'modifystart' ? 'grabbing' : 'pointer';
  if(evt.type==='modifyend'){
	  // отчистил
	  emptyObject(ObjOverTrackData);
	  TrackName.length=0;
	  gpxUrls.length=0;
	  TrackOverlay.getSource().clear(true);
	  vectorLayer.getSource().clear(true);
	//  enduroAngleLayer.getSource().clear(true);
	  featureOverlay.getSource().clear(true);
	  
	  

	 // let coord=evt.mapBrowserEvent.coordinate;
	  let coord = transform(evt.mapBrowserEvent.coordinate, 'EPSG:3857', 'EPSG:4326');
	   get('https://endurotrack.ru/cgi-bin/getgpx.pl?lat='+coord[1]+'&lon='+coord[0], function (data) {
	 // if(JSON.isRawJSON(data)){
		let myid;
	   const obj = JSON.parse(data,(key, value) => {
		   
  		   if(key==="id"){
			myid='https://endurotrack.ru/cgi-bin/getgpx.pl?track='+value.toString();
			gpxUrls.push(myid);
			
		   }
  		   if(key==="name"){
			TrackName[myid]=value.toString();
		   }
		   

		});
		
	 downloadgpx(); 
	 // }
  });
  }
});




const ovSource = modify.getOverlay().getSource();
ovSource.on(['addfeature', 'removefeature'], function (evt) {
  document.getElementById('map').style.cursor = evt.type === 'addfeature' ? 'pointer' : '';
});

map.addInteraction(modify);

const enduroAngleLayer = new VectorLayer({
  source: new VectorSource(),
  map: map,
  style:style.enduroAngle,
  });

enduroAngleLayer.getSource().addFeature(enduroAngle); 
enduroAngleLayer.setVisible(false);
map.render();



dragAndDropInteraction.on('addfeatures', function (event) {

 // const vectorSource = new VectorSource({
 //   features: event.features,
 // });

   loadGPXFeatures(event.features);

  //vectorSource.addFeatures(event.features);
 // map.addLayer(
 //   new VectorLayer({
 //     source: vectorSource,
 //   }),
 // );
  map.getView().fit(vectorSource.getExtent());
});




const displayFeatureInfo = function (pixel) {
  const features = [];

  map.forEachFeatureAtPixel(pixel, function (feature) {
    features.push(feature);
  });
  if (features.length > 0) {
	   
    const info = [];
    let i, ii;
    for (i = 0, ii = features.length; i < ii; ++i) {

	 if(features[i].getGeometry().getType()=='MultiLineString')
	 {
	 
      info.push(features[i].get('name'));
      console.log(features[i].get('name'));
	//	 info.push(features[i]);  
	 var lonlat = transform(features[i].getGeometry().getFirstCoordinate(), 'EPSG:3857', 'EPSG:4326');
	 var lon = lonlat[0];
	 var lat = lonlat[1];

	//console.log(map.getView().setCenter(features[i].getGeometry().getFirstCoordinate()));
		//map.view.setCenter(features[i].getGeometry().getFirstCoordinate());

	 }
    }
	
	
    document.getElementById('info').innerHTML = info.join(', ') || '(unknown)';
    map.getTarget().style.cursor = 'pointer';
	
	
  } else {
    document.getElementById('info').innerHTML = '&nbsp;';
    map.getTarget().style.cursor = '';
  }
};


/*
const locate = document.createElement('div');
locate.className = 'ol-control ol-unselectable locate';
locate.innerHTML = '<button title="Locate me">◎</button>';

locate.addEventListener('click', function () {
const myfpoint=new Feature();
let coords;	
navigator.geolocation.getCurrentPosition(position => {
  const { latitude, longitude } = position.coords;
	const coords = [longitude, latitude];
    const accuracy = circular(coords, position.coords.accuracy);  
	
	//const myfpoint=new Feature(new Point(fromLonLat(coords)));
  // Show a map centered at latitude / longitude.
     myfpoint.setGeometry(new Point(fromLonLat(coords)));
	 console.log(fromLonLat(coords));
     vectorSource.addFeatures([
      new Feature(
        accuracy.transform('EPSG:4326', map.getView().getProjection())
      ),
      myfpoint,
    ]);
});



*/

//map.addControl(
//  new Control({
//   controls: defaultControls().extend([new FullScreen({ source: 'fullscreen', }),])
//  })
//  );
  





const TrackOverlay = new VectorLayer({
  source: new VectorSource(),
  map: map,
  style:styleFunctionforMytrack,
  });

// отрисовываю трек по верх основного
const updateNewTrack =function(closestFeature){
let len=0;

 //const features=vectorSource.getFeatures();
 //for(let i=0;i<features.length;i++){
  vectorSource.forEachFeature(function (feature) {
  // const feature=features[i];
   const geometry =
       (
        feature.getGeometry()
      );

	
	let data=ObjOverTrackData[feature.ol_uid];//geometry.get('up_track_data');
    
	 console.log('TrackOverlay '+TrackOverlay.getSource().getFeatures().length);
	 console.log('vectorSource'+vectorSource.getFeatures().length);
	 
	if(!data){	
	
		//let highlight = feature.get('highlight2');
		//if (highlight) {
		//	TrackOverlay.getSource().removeFeature(highlight); 
		//}
		//TrackOverlay.getSource().removeFeature(feature.get('highlight2')); 
	  //vectorSource.removeFeature(feature.get('highlight2'));	
	  return;
	}
	
	let arr;
	if(feature!=closestFeature){
		//arr=[closestFeature.getGeometry().getFirstCoordinate()];	
		arr=data.data;	
		//TrackOverlay.getSource().clear(true);
		//TrackOverlay.getSource().removeFeature(highlight); 
        return;		
	}else{
		arr=data.data;

	} 
	
	
    const coordinate = geometry.getFirstCoordinate();
	if (coordinate === null) {
		info.innerHTML ='Нет данных';
	}else
	{

	let highlight = feature.getGeometry().get('highlight2');
	
		if (highlight === undefined) {
		  highlight = new Feature(new MultiLineString(arr));
        //  highlight.setStyle(styleFunctionforMytrack(feature));
		//  highlight.set('name','overtrack'+TrackOverlay.getSource().getFeatures().length);
		  feature.getGeometry().set('highlight2', highlight);
		
		//TrackOverlay.getSource().addFeature(highlight); 
		  console.log(TrackOverlay.getSource().getFeatures().length);
		  
			CurrentTrack=highlight.getGeometry();
		  
		} else {
			highlight.getGeometry().setCoordinates(arr);
			CurrentTrack=highlight.getGeometry();
		}
		
		 

		
	}

	// map.render();
  });	

//return len;
	

//	if(myline===null){
//			myline = new MultiLineString(newcoor);
//	}else {
//			myline.setCoordinates(newcoor);
//	}
	
	
}


const styletext = new Style({
  stroke: new Stroke({
      color: '#000',
      width: 5,
    }),

});

let point = null;
let line = null;
let myline=null;
var len=0;


function updateRotationStyle(start,end) {
 // const ang=parseFloat(controls['rotation'].value) * Math.PI;	
 	var dx =olSphere.getDistance(toLonLat(start),toLonLat(end));
	var dy =(end[2]-start[2]);

	var rotation = Math.atan2(dy, dx);
//	console.log('rotation='+(-rotation* Math.PI));
	let Tek_style=enduroAngle.getStyle();
	
  
	if(start[0]>end[0]){
		enduroAngle.setStyle(style.enduroAngle_mirror);
		Tek_style=enduroAngle.getStyle();
		dy=-dy;
		Tek_style
		.getImage()
		.setRotation(rotation);//ang* Math.PI);

		Tek_style
		.getText()
		.setText((rotation/Math.PI*180).toFixed(1)+'°');
		
	}else{
		enduroAngle.setStyle(style.enduroAngle);
		Tek_style=enduroAngle.getStyle();
		
		Tek_style
		.getImage()
		.setRotation(-rotation);//ang* Math.PI);

		Tek_style
		.getText()
		.setText((rotation/Math.PI*180).toFixed(1)+'°');
		
	}
	


  Tek_style.getImage().setRotateWithView(false);
  enduroAngle.changed();
}


// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// через геометрию не получается в динамике рисовать после добавления 10 шт. ошибка
// поэтому рисуем на прямую
TrackOverlay.on('postrender', function (evt) {
  const vectorContext = getVectorContext(evt);
 
 // vectorContext.setStyle(styleFunctionforMytrack()[0]);
 // if (CurrentTrack !== null) {
 //   vectorContext.drawGeometry(CurrentTrack);
 // }
//	evt.target.setStyle(styleFunctionforMytrack());

	vectorSource.forEachFeature(function (feature) {
	  
		//vectorContext.setStyle(styleFunctionforMytrack()[0]);
		//TrackOverlay.getSource().clear(true);
		let dataarr=ObjOverTrackData[feature.ol_uid];
		if((dataarr)&&(dataarr.data!=null)){
			let newline = new Feature(new MultiLineString(dataarr.data));
          //  newline.setStyle(styleFunctionforMytrack);
			const st=feature.getStyle();
			if(st){
			//	var stroke=st[0].getStroke();
			//	stroke.setWidth(7);
			//	feature.setStyle(st);
			//	st[0].setZIndex(1);
			}
		//	feature.setStyle(styleFunctionforMytrack);

			TrackOverlay.setZIndex(1);
			vectorLayer.setZIndex(0);
			vectorContext.setStyle(styleFunctionforMytrack(feature)[0]);
			vectorContext.drawGeometry(newline.getGeometry());
		//	vectorContext.drawFeature(newline);
		}else{
			const st=feature.getStyle();
		//	if(st[0]){
		//		if(st.name==='styleFunction'){
				//	st=st.call();
		//		}
			//	var stroke=st[0].getStroke();
			//	stroke.setWidth(5);
			//	st[0].setZIndex(0);
			//	feature.setStyle(st);
		//	}
		//	vectorLayer.setStyle(styleFunctionforMytrack);
			
		}
		
		
		
		if(CurrentFeature===feature){
		//	vectorContext.drawFeature(feature,styleFunctionforMytrack()[0]);
		}
		else{
		//	feature.getGeometry().setCoordinates([[0,0],[0,0]]);
		}
//		console.log(evt);
//		console.log(feature);
	});

});	


const displaySnap = function (coordinate,feature) {
	
let closestFeature;	
let mouse=0;

if(feature===undefined){
	// по мышке
  if(!coordinate){return;}
  closestFeature = vectorSource.getClosestFeatureToCoordinate(coordinate,function (feature) {return !feature.get('hidden'); });
  if(!closestFeature){return;}

 CurrentFeature=closestFeature;
  mouse=1;
  
  vectorSource.forEachFeature(function (feature) {
	  if(closestFeature.ol_uid!=feature.ol_uid){
			if(ObjOverTrackData[feature.ol_uid]){ObjOverTrackData[feature.ol_uid].data=null;}
	  }
  });

 
//   map.render();
}else  // по времени
{
	closestFeature=feature;
}
 // closestFeature = closestFeature.get('highlight');//;
 if(closestFeature.get('hidden')){return;}

  const info = document.getElementById('info');
  const infoele = document.getElementById('infoele');
  if ((closestFeature === null)||(closestFeature === undefined)) {
    point = null;
    line = null;
	myline=null;
    info.innerHTML = '&nbsp;';
  } else {
	  
	// let highlight = closestFeature.get('highlight2');
	let geometry;

	 geometry = closestFeature.getGeometry();
	const closestPoint = geometry.getClosestPoint(coordinate);

	
    if (point === null) {
      point = new Point(closestPoint);
    } else {
      point.setCoordinates(closestPoint);
    }
    const date = new Date(closestPoint[3] * 1000);
	 var lonlat = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
	 var lon = lonlat[0].toFixed(4);
	 var lat = lonlat[1].toFixed(4);

//	console.log(closestFeature.GPXMetadata);
//    info.innerHTML =closestFeature.get('name')+' '+date.toLocaleString()+' ('+lat+','+lon+')';//coordinate.transform(new Projection("EPSG:4326"), map.getProjectionObject())+
   
//   if(closestPoint.length>2){	infoele.innerHTML ='Высота='+closestPoint[1].toFixed(1);}
	
	
	
	var coordsarray = geometry.getCoordinates();
	var startpoint = 0;
	let found=-1;
	
//	let arr_i=0;
//	for (arr_i = 0; arr_i < coordsarray.length; arr_i++) {
//		let ar=coordsarray[arr_i];
//		found = ar.findIndex(function(itm) {
//			return itm[0].toFixed(3) === closestPoint[0].toFixed(3) && itm[1].toFixed(3) === closestPoint[1].toFixed(3);
//		});
//		if(found>=0){break;}
//	}
//	 console.log('found='+closestPoint[4]);
//	 console.log('found='+Math.ceil(closestPoint[4]));
    if(closestPoint.length>4){	found=Math.ceil(closestPoint[4]);}

// обновляем трек
	if(found>=0){
		let newcoor=[];	
		let count=0;
		for (let i = 0; i < coordsarray.length; i++) {
			if((coordsarray[i].length+count-1)>found){
				var ar=coordsarray[i].slice(startpoint,found-count);
				ar.push(closestPoint); // добавляю координаты по мышке
				newcoor.push(ar);
				break;
			}else{
				newcoor.push(coordsarray[i]);
				count=count+coordsarray[i].length;
			}
		}
		
		let data= new OverTrackData();
	    data.name=closestFeature.ol_uid;
		data.data=newcoor;
		geometry.set('up_track_data',data);
		ObjOverTrackData[closestFeature.ol_uid]=data;
		
	
	//	geometry.set('up_track_data',newcoor);
		if(mouse){
		updateNewTrack(closestFeature);
		}else{updateNewTrack();}
	//	console.log('len='+len);
	}
	
 info.innerHTML ='Дистанция точки:'+(closestPoint[5]/1000).toFixed(1)+' км.';//coordinate.transform(new Projection("EPSG:4326"), map.getProjectionObject())+
 if(closestPoint[2]!=undefined){	infoele.innerHTML ='Высота точки:'+closestPoint[2].toFixed(1)+' ('+lat+','+lon+')';}

	const infoonmap = document.getElementById('infoonmap');
	infoonmap.innerHTML =closestFeature.get('name')+'<br>'+date.toLocaleString();
	infoonmap.style.opacity = 1;
	
      //closestFeature.get('PLT') + ' (' + date.toLocaleString() + ')';
	// линия от мышки до трека
    const coordinates = [coordinate, [closestPoint[0], closestPoint[1]]];
    if (line === null) {
      line = new LineString(coordinates);
    } else {
      line.setCoordinates(coordinates);
    }
	
// угол
	var start=closestPoint;
	var end=closestPoint;
	let count=0;
    // в каком подмассиве точка
	for (let i = 0; i < coordsarray.length; i++) {
		if((coordsarray[i].length+count-1)>Math.floor(start[4])){	
			end=coordsarray[i][Math.floor(start[4])-count+1];
			break;
		}
		count=count+coordsarray[i].length;
	}
	
	enduroAngle.getGeometry().setCoordinates(coordinate);
	updateRotationStyle(start,end);
	
	//GPXCheckfeature.getGeometry().setCoordinates(coordinate);

	
  }	
  
  
  map.render();
};

map.on('pointermove', function (evt) {
  if (evt.dragging) {
    return;
  }
  const coordinate = map.getEventCoordinate(evt.originalEvent);
  displaySnap(coordinate);
});



const popup = new Overlay({
  element: document.getElementById('popup'),
  offset:[-30,-20],
  positioning:'top-left',
}); 
map.addOverlay(popup);



map.on('click', function (evt) {
  displaySnap(evt.coordinate);

  
 // GPXCheckfeature.getGeometry().setCoordinates(evt.coordinate);
  
  const closestFeature = vectorSource.getClosestFeatureToCoordinate(evt.coordinate);
  const geometry = closestFeature.getGeometry();
  const closestPoint = geometry.getClosestPoint(evt.coordinate);
  
 // map.getOverlays().clear();

  
  popup.getElement().innerHTML ='<label>'+(closestPoint[5]/1000).toFixed(1)+' км.</label>';
  popup.setPosition(evt.coordinate);
  map.render();
//  popup.set('id',closestPoint[5]);

 // window.setTimeout(() => {
//	map.getOverlays().forEach(function (el){
//								//	if(el.get('id')!=closestPoint[5]){map.removeOverlay(el);}
//								map.removeOverlay(el);
//							}); 
 // },3000);
    
 // displayFeatureInfo(evt.pixel);
 

//  const element = popup.getElement();
//  const hdms = (toLonLat(evt.coordinate));
//  popup.setPosition(evt.coordinate);
//  let popover = bootstrap.Popover.getInstance(element);
//  if (popover) {
//    //popover.dispose();
//	popover.setContent({
//	  '.popover-header': 'another title',
//	  '.popover-body': '<p>The location you clicked was:</p><code>' + hdms + '</code>',
//	})
//  }else{
//  popover = new bootstrap.Popover(element, {
//    animation: false,
 //   container: element,
//    content: '<p>The location you clicked was:</p><code>' + hdms + '</code>',
//    html: true,
//    placement: 'auto',
//    title: 'Welcome to OpenLayers',
//  });
//  }
 // popover.show(); 
 
});


// установка элементов на странице
function setControlPos(){
	
const timeele = document.getElementById('time');
const viewangl = document.getElementById('viewangl');
const viewangl_label = document.getElementById('viewangl_label');
const GPXcheckDom = document.getElementById('viewgpx');
const GPXcheckDom_label = document.getElementById('viewgpx_label');

const gpxlistDom = document.getElementById('gpxlist');


const infoonmap = document.getElementById('infoonmap');
const info = document.getElementById('info');

timeele.hidden = false;
timeele.style.visibility = "visible";

timeele.style.width=(document.getElementById('map').offsetHeight*0.6)+'px'; 
let h=(document.getElementById('map').offsetHeight-document.getElementById('sidepanel').offsetHeight-
info.offsetHeight- timeele.offsetWidth/2)+'px'; 
info.right=10;//document.getElementById('map').offsetWidth+'px';
//let h=(map.getSize()[0]-info.offsetHeight)+'px'; 
timeele.style.left=document.getElementById('map').offsetWidth-timeele.offsetWidth/2-timeele.offsetHeight-5+'px';
timeele.style.top=h;

map.addControl(
  new Control({
    element: timeele,
  }),
 new Control({
    element: infoonmap,
  }) , 
 new Control({
    element: viewangl,
  }),  
 new Control({
    element: viewangl_label,
  }),
 new Control({
    element: GPXcheckDom,
  }),
 new Control({
    element: GPXcheckDom_label,
  }),
  new Control({
    element: gpxlistDom,
  }),
  );  
 	
	
}


viewangl.addEventListener("click", () => {
  enduroAngleLayer.setVisible(viewangl.checked);
        });
		
viewgpx.addEventListener("click", () => {

  GGPXCheckLayer.setVisible(viewgpx.checked);
  GPXCheckfeature.getGeometry().setCoordinates(map.getView().getCenter());
  
    const updates = {};
    updates['saturation'] = -1;
	
    //raster.updateStyleVariables(updates);
	if(viewgpx.checked){
	 document.querySelector('canvas').style.filter="grayscale(90%)";

   gpxlist.hidden = false;
   gpxlist.style.visibility = "visible";

   const gpxlist_ulDom = document.getElementById('gpxlist_ul');
   gpxlist_ulDom.hidden = false;
   //gpxlist_ulDom.style.display="initial";

	}else{
	 document.querySelector('canvas').style.filter="grayscale(0%)";
   gpxlist.hidden = true;
   gpxlist.style.visibility = "unvisible";

   const gpxlist_ulDom = document.getElementById('gpxlist_ul');
   //gpxlist_ulDom.style.display="none";
   gpxlist_ulDom.hidden = true;

	}
  
  });



		

window.addEventListener("resize", function() {

  setControlPos();

}, false);


const stroke = new Stroke({
  color: 'rgba(255,0,0,0.9)',
  width: 2,
});

const stylepoint = new Style({
  stroke: stroke,
  image: new CircleStyle({
    radius: 5,
    fill: null,
    stroke: stroke,
  }),
});


vectorLayer.on('postrender', function (evt) {
  const vectorContext = getVectorContext(evt);
 
  vectorContext.setStyle(stylepoint);
  if (point !== null) {
    vectorContext.drawGeometry(point);
  }
  if (line !== null) {
    vectorContext.drawGeometry(line);
  }
  if (myline !== null) {
    vectorContext.setStyle(stylemyline);
    vectorContext.drawGeometry(myline);

  }
  
});

map.on('loadstart', function () {
//  map.getTargetElement().classList.add('spinner');
  document.getElementById('time').hidden=true;
});

map.on('loadend', function () {
//  map.getTargetElement().classList.remove('spinner');
  setControlPos();
});



const featureOverlay = new VectorLayer({
  source: new VectorSource(),
  map: map,
  style:new Style({
    image: new CircleStyle({
      radius: 5,
      fill: new Fill({
        color: 'rgba(255,0,0,0.9)',
      }),
    }),
  }),
  });

// -------------- надпись
const labelStyle = new Style({
  text: new Text({
    font: '13px Calibri,sans-serif',
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 4,
    }),
  }),
});

const TextOverlay = new VectorLayer({
  source: new VectorSource(),
  map: map,
  style:labelStyle,
  });
// --------------



  

function lerp(start, end, t) {
  return start * (1 - t) + end * t
}

// изза того что координата M теперь не последняя в point 
function lineStringCoordinateAtM(
  flatCoordinates,
  offset,
  end,
  stride,
  m,
  extrapolate,
) {
  if (end == offset) {
    return null;
  }
  let coordinate;
  if (m < flatCoordinates[offset + stride - 3]) {
    if (extrapolate) {
      coordinate = flatCoordinates.slice(offset, offset + stride);
      coordinate[stride - 3] = m;
      return coordinate;
    }
    return null;
  }
  if (flatCoordinates[end - 3] < m) {
    if (extrapolate) {
      coordinate = flatCoordinates.slice(end - stride, end);
      coordinate[stride - 3] = m;
      return coordinate;
    }
    return null;
  }
  // FIXME use O(1) search
  if (m == flatCoordinates[offset + stride - 3]) {
    return flatCoordinates.slice(offset, offset + stride);
  }
  let lo = offset / stride;
  let hi = end / stride;
  while (lo < hi) {
    const mid = (lo + hi) >> 1;
    if (m < flatCoordinates[(mid + 1) * stride - 3]) {
      hi = mid;
    } else {
      lo = mid + 1;
    }
  }
  const m0 = flatCoordinates[lo * stride - 3];
  if (m == m0) {
    return flatCoordinates.slice((lo - 3) * stride, (lo - 3) * stride + stride);
  }
  const m1 = flatCoordinates[(lo + 1) * stride - 3];
  const t = (m - m0) / (m1 - m0);
  coordinate = [];
  for (let i = 0; i < stride - 1; ++i) {
    coordinate.push(
      lerp(
        flatCoordinates[(lo - 1) * stride + i],
        flatCoordinates[lo * stride + i],
        t,
      ),
    );
  }
  coordinate.push(m);
  return coordinate;
}

function mylineStringsCoordinateAtM(
  flatCoordinates,
  offset,
  ends,
  stride,
  m,
  extrapolate,
  interpolate,
) {
  if (interpolate) {
    return lineStringCoordinateAtM(
      flatCoordinates,
      offset,
      ends[ends.length - 1],
      stride,
      m,
      extrapolate,
    );
  }
  let coordinate;
  if (m < flatCoordinates[stride - 3]) {
    if (extrapolate) {
      coordinate = flatCoordinates.slice(0, stride);
      coordinate[stride - 1] = m;
      return coordinate;
    }
    return null;
  }
  if (flatCoordinates[flatCoordinates.length - 3] < m) {
    if (extrapolate) {
      coordinate = flatCoordinates.slice(flatCoordinates.length - stride);
      coordinate[stride - 1] = m;
      return coordinate;
    }
    return null;
  }
  for (let i = 0, ii = ends.length; i < ii; ++i) {
    const end = ends[i];
    if (offset == end) {
      continue;
    }
    if (m < flatCoordinates[offset + stride - 3]) {
      return null;
    }
    if (m <= flatCoordinates[end - 3]) {
      return lineStringCoordinateAtM(
        flatCoordinates,
        offset,
        end,
        stride,
        m,
        false,
      );
    }
    offset = end;
  }
  return null;
}
 

const control = document.getElementById('time');
const info = document.getElementById('info');
 
control.addEventListener('input', function () {
  const value = parseInt(control.value, 10) / 1000;

  vectorSource.forEachFeature(function (feature) {

  if(feature.get('hidden')){return;}

  let time=feature.get('time');	
  const m = time.start + time.duration * value;
  console.log('name='+feature.get('name'));
  console.log('m='+m);

    const geometry =
      /** @type {import("../src/ol/geom/LineString.js").default} */ (
        feature.getGeometry()
      );

	
	//так как точка модифицирована при загрузке возращаемая координата не там, перекладываем
//    const coordinate = geometry.getCoordinateAtM(m, true);
	const coordinate=mylineStringsCoordinateAtM(geometry.flatCoordinates,
      0,
      geometry.ends_,
      geometry.stride,
      m,
      true,
      false);
	

	if (coordinate === null) {
		info.innerHTML ='Нет данных';
	}else
	{
		displaySnap(coordinate,feature);
	//так как точка модифицирована при загрузке возращаемая координата не там, перекладываем
		//coordinate[3]=coordinate[5];
	//	const date = new Date(coordinate[5] * 1000);
	//	info.innerHTML =date.toLocaleString()+' '+coordinate[0];
		
		let highlight = feature.get('highlight');
		if (highlight === undefined) {
		  highlight = new Feature(new Point(coordinate));
		  feature.set('highlight', highlight);
    	  const st=new Style({
					image: new CircleStyle({
					  radius: 5,
					  fill: new Fill({
						color: colors[feature.ol_uid],
					  }),
					stroke:new Stroke({
						color: '#000000',
						width: 1,
					}),
					}),
					
			});
				  
 		  highlight.setStyle(st);
		  featureOverlay.getSource().addFeature(highlight);
		} else {
		  highlight.getGeometry().setCoordinates(coordinate);
		}
	}
  });
  map.render();
});


const opacityInput = document.getElementById('opacity-input');
const opacityOutput = document.getElementById('opacity-output');


let tg = window.Telegram.WebApp;

Telegram.WebApp.onEvent('mainButtonClicked', function(){
 // let title = document.getElementsByClassName("title-inp")[0];
 // let description = document.getElementsByClassName("desc-inp")[0];
 // let text = document.getElementsByClassName("text-inp")[0];

  
  let data = {
      title:"test", //title.value,
      desc:"Desk", //description.value,    
      text:"text", //text.value
  }

  tg.sendData(JSON.stringify(data));
tg.close();

});

//const userinfo = [];
//if(undefined!=tg.initDataUnsafe.user){
//	undefined==tg.initDataUnsafe.user.username?userinfo.push("no username"):userinfo.push(tg.initDataUnsafe.user.username);
//	undefined==tg.initDataUnsafe.user.first_name?userinfo.push("no first_name"):userinfo.push(tg.initDataUnsafe.user.first_name);
//}
//document.getElementById('info').innerHTML = userinfo.join(', ') || '(unknown user)';
console.log(tg.initDataUnsafe);
tg.expand();
tg.disableVerticalSwipes();

tg.MainButton.text = "Скачать"; //изменяем текст кнопки 
//tg.MainButton.setText("Changed Text1"); //изменяем текст кнопки иначе
//tg.MainButton.textColor = "#F55353"; //изменяем цвет текста кнопки
//tg.MainButton.color = "#143F6B"; //изменяем цвет бэкграунда кнопки
//tg.MainButton.setParams({"color": "#143F6B"}); //так изменяются все параметры 
//tg.MainButton.show(); //показываем 

/*
let sBtn = document.getElementsByClassName("s-btn")[0];
sBtn.addEventListener("click", () => {
            let title = document.getElementsByClassName("title-inp")[0];
            let description = document.getElementsByClassName("desc-inp")[0];
            let text = document.getElementsByClassName("text-inp")[0];

            
            let data = {
                title: title.value,
                desc: description.value,    
                text: text.value
            }

            tg.sendData(JSON.stringify(data));
			tg.close();
        });
*/


//function update() {
//  const opacity = parseFloat(opacityInput.value);
//  rastersat.setOpacity(opacity);
//  opacityOutput.innerText = opacity.toFixed(2);
//}
//opacityInput.addEventListener('input', update);
//update();